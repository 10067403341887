import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

export const toStringQuestions = (questions = []) => {
  return questions.map((questionItem) => {
    const question = questionItem.title
    const answer = documentToPlainTextString(questionItem.text)

    return { question, answer }
  })
}
