
import { RevIllustration } from '@ds/components/Illustration'

import { imageValidator } from '../../helpers/image'

export default {
  components: {
    RevIllustration,
  },

  props: {
    imageProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasValidImage() {
      const { src, alt, width, height } = this.imageProps

      return imageValidator({ src, alt, width, height })
    },
  },
}
