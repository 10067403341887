
import { RevButton } from '@ds/components/Button'
import isEmpty from 'lodash/isEmpty'

import { prepareJsonLdScript } from '@core/helpers'
import { buildFaqSchema } from '@core/helpers/head/schema'
import { trackClick } from '@tracking/events'

import RichTextEditorParser from '../RichTextEditorParser'
import BlockTitle from '../private/BlockTitle'

import FaqContentItem from './FaqContentItem.vue'
import { STRUCTURED_DATA_TYPES } from './constants'
import { toStringQuestions } from './helper'

export default {
  components: {
    RevButton,
    BlockTitle,
    FaqContentItem,
    RichTextEditorParser,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    ctaLink: {
      type: Object,
      default: () => ({}),
    },

    rendering: {
      type: String,
      default: STRUCTURED_DATA_TYPES.JSON_AND_HTML,
      validator: (value) =>
        Object.values(STRUCTURED_DATA_TYPES).includes(value),
    },

    questions: {
      type: Array,
      default: () => [],
    },

    tracking: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      ctaLinkHref: this.ctaLink?.link?.href,
    }
  },

  head() {
    if (
      this.rendering === STRUCTURED_DATA_TYPES.HTML_ONLY ||
      isEmpty(this.questions)
    ) {
      return {}
    }

    const stringQuestions = toStringQuestions(this.questions)
    const schema = buildFaqSchema(stringQuestions)
    const faqSchemaScript = prepareJsonLdScript({ schema })

    return {
      script: faqSchemaScript,
      __dangerouslyDisableSanitizers: ['script'],
    }
  },

  computed: {
    hasQuestions() {
      return (
        !isEmpty(this.questions) &&
        this.rendering !== STRUCTURED_DATA_TYPES.JSON_ONLY
      )
    },

    hasLink() {
      return !isEmpty(this.ctaLink)
    },

    isFaqEmpty() {
      return !this.hasLink && !this.hasQuestions
    },

    getLinkTarget() {
      return this.ctaLink.link?.targetBlank ? '_blank' : '_self'
    },
  },

  methods: {
    sendTrackingData() {
      trackClick({
        ...this.tracking,
        name: 'Faq',
      })
    },
  },
}
